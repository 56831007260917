import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { LogOut } from '../../Redux/SiteWideSettings';

import '../../Style/NavBar.css';

function CustomNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserAccount = useSelector((state) => state?.SiteWideSettings?.UserAccount);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showSupportDropdown, setShowSupportDropdown] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [showAdminDropdown, setShowAdminDropdown] = useState(false);

  const handleHome = () => {
    navigate('/');
  }

  const handleLogout = () => {
    dispatch(LogOut());
  };

  return (
    <div>
      <Navbar expand='lg' variant='dark' className='Theme'>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <NavLink className='navbar-brand ms-3' to='/' onClick={handleHome}>Waoo Network</NavLink>
            {UserAccount?.IsLoggedIn === false &&
              <NavDropdown title='Services' show={showServicesDropdown} onSelect={() => setShowServicesDropdown(false)} onMouseEnter={() => setShowServicesDropdown(true)} onMouseLeave={() => setShowServicesDropdown(false)}>
                <NavLink className='dropdown-item' to='/services/webdevelopment'>Web Development</NavLink>
                <NavLink className='dropdown-item' to='/services/datarecovery'>Data Recovery</NavLink>
              </NavDropdown>
            }
            {UserAccount?.IsLoggedIn === true && (
              <>
                <NavDropdown title='Support' show={showSupportDropdown} onSelect={() => setShowSupportDropdown(false)} onMouseEnter={() => setShowSupportDropdown(true)} onMouseLeave={() => setShowSupportDropdown(false)}>
                  <NavLink className='dropdown-item' to='/support/knowledgebase'>Knowledge Base</NavLink>
                  <NavLink className='dropdown-item' to='/support/contactsupport'>Contact Support</NavLink>
                </NavDropdown>
                <NavDropdown title='Account' show={showAccountDropdown} onSelect={() => setShowAccountDropdown(false)} onMouseEnter={() => setShowAccountDropdown(true)} onMouseLeave={() => setShowAccountDropdown(false)}>
                  <NavLink className='dropdown-item' to='/account/workorders'>Work Orders</NavLink>
                  <NavLink className='dropdown-item' to='/account/invoices'>Invoices</NavLink>
                  <NavLink className='dropdown-item' to='/account/calendar'>Calendar</NavLink>
                </NavDropdown>
              </>
            )}
            {UserAccount?.IsLoggedIn === true && UserAccount?.UserType === 'Admin' && (
              <>
                <NavDropdown title='Accounts' show={showAdminDropdown} onSelect={() => setShowAdminDropdown(false)} onMouseEnter={() => setShowAdminDropdown(true)} onMouseLeave={() => setShowAdminDropdown(false)}>
                  <NavLink className='dropdown-item' to='/accounts/customers'>Customers</NavLink>
                  <NavLink className='dropdown-item' to='/accounts/contracts'>Contracts</NavLink>
                </NavDropdown>
                <NavDropdown title='Settings'>
                  <NavLink className='dropdown-item' to='/settings/useraccounts'>User Accounts</NavLink>
                  <NavLink className='dropdown-item' to='/settings/websocketstats'>WebSocket Stats</NavLink>
                </NavDropdown>
              </>
            )}
            <Nav.Item>
              <NavLink className='nav-link' to='/contactus'>Contact Us</NavLink>
            </Nav.Item>
          </Nav>
          {UserAccount?.IsLoggedIn === true ? (
            <NavDropdown className='me-0 me-lg-4 ms-lg-auto' title={`${UserAccount?.FirstName}`}>
              <NavLink className='dropdown-item' to='/account/profile'>Profile</NavLink>
              <button className='dropdown-item' onClick={handleLogout}>Log Out</button>
            </NavDropdown>
          ) : (
            <NavLink className='nav-link me-lg-4' to='/login'>Login</NavLink>
          )
          }
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default CustomNavbar;
