import { routeWatchVersionUrl } from '../Config/apiconfig';
import { encryptUsingAES256Base64 } from '../Components/Security/Crypto';
import { ApiCall } from '../Components/ApiCall';
import packageJson from '../../package.json';

class VersionWatcher {
  constructor() {
    this.callbacks = new Set();
    this.currentVersion = null;
    this.clientVersion = packageJson.version;
    this.appName = 'WN';
    this.initialize();
  }

  async initialize() {
    await this.checkVersion();
    this.connect();
  }

  async checkVersion() {
    try {
      const data = {
        'AppName': this.appName
      };

      const response = await ApiCall('usp_AppVersion', data, 'WNSql');
      
      if (response?.data?.Table?.[0]) {
        const newVersion = response?.data?.Table?.[0]?.Version;
        if (newVersion && newVersion.trim() !== '') {
          const oldVersion = this.currentVersion;
          this.currentVersion = newVersion;
          
          // Convert versions to strings by removing dots, preserving zeros
          const clientNum = this.clientVersion.replace(/\./g, '');
          const serverNum = newVersion.replace(/\./g, '');

          // If version changed, notify subscribers
          if (oldVersion !== newVersion) {
            this.notifySubscribers();
          }

          if (clientNum > serverNum) return 'ahead';
          if (clientNum < serverNum) return 'behind';
          return 'same';
        }
      }
      return null; // Return null if version check was unsuccessful
    } catch (error) {
      console.error('Version check failed:', error);
      return null;
    }
  }

  connect() {
    try {
      console.log('Starting connection setup');
      const data = { App: this.appName };
      const encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
      const wsUrl = routeWatchVersionUrl.replace('https', 'wss');
      console.log('Connection URL: ', wsUrl);
      this.ws = new WebSocket(`${wsUrl}?encryptedData=${encodeURIComponent(encryptedData)}`);
      console.log('WebSocket instance created');

      this.ws.onopen = () => {
        console.log('WebSocket connection established');
      };

      this.ws.onmessage = (event) => {
        console.log('Message received:', event.data);
        // Ignore ping messages
        if (event.data === 'ping') {
          return;
        }

        try {
          const data = JSON.parse(event.data);
          if (data.app === this.appName && data.newVersion !== this.currentVersion) {
            this.currentVersion = data.newVersion;
            this.notifySubscribers();
          }
        } catch (error) {
          console.error('Error processing version update:', error);
          console.error('Received data:', event.data);
        }
      };

      this.ws.onclose = () => {
        console.log('WebSocket connection closed, attempting reconnect...');
        setTimeout(() => this.connect(), 5000);
      };

      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        this.ws.close();
      };

      console.log('Connection setup complete');
    } catch (error) {
      console.error('Connection setup failed:', error);
      setTimeout(() => this.connect(), 5000);
    }
  }

  subscribe(callback) {
    this.callbacks.add(callback);
    return () => this.callbacks.delete(callback);
  }

  notifySubscribers() {
    this.callbacks.forEach(callback => callback());
  }
}

export default new VersionWatcher();