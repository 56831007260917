import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RequireAuth from '../Security/RequireAuth';
import Home from '../../Pages/Home';
import ContactUs from '../../Pages/ContactUs';
import Login from '../../Pages/UserAccount/Login';
import LoginMfa from '../../Pages/UserAccount/LoginMfa';
import ResetPassword from '../../Pages/UserAccount/ResetPassword';
import PasswordResetConfirm from '../../Pages/UserAccount/PasswordResetConfirm';
import Error404 from '../../Pages/Error/Error404';
import UserAccounts from '../../Pages/UserAccount/UserAccounts';
import Contracts from '../../Pages/Accounts/Contracts';
import Customers from '../../Pages/Accounts/Customers';
import Profile from '../../Pages/Account/Profile';
import Invoices from '../../Pages/Account/Invoices';
import Calendar from '../../Pages/Account/Calendar';
import ContactSupport from '../../Pages/Support/ContactSupport';
import KnowledgeBase from '../../Pages/Support/KnowledgeBase';
import DataRecovery from '../../Pages/Services/DataRecovery';
import WebDevelopment from '../../Pages/Services/WebDevelopment';
import WorkOrders from '../../Pages/Account/WorkOrders';
import WebSocketStats from '../../Pages/Settings/WebSocketStats';

function SiteRoutes() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='ContactUs' element={<ContactUs />} />
      <Route path='Login' element={<Login />} />
      <Route path='LoginMfa' element={<LoginMfa />} />
      <Route path='ResetPassword' element={<ResetPassword />} />
      <Route path='PasswordResetConfirm/:ResetRowGuid' element={<PasswordResetConfirm />} />
      <Route path='Booking/:Source' element={<Home />} />
      <Route path='Settings'>
        <Route path='UserAccounts' element={<UserAccounts />} />
        <Route path='WebSocketStats' element={<WebSocketStats />} />
      </Route>
      <Route path='Services'>
        <Route path='DataRecovery' element={<DataRecovery />} />
        <Route path='WebDevelopment' element={<WebDevelopment />} />
      </Route>
      <Route path='Support'>
        <Route path='ContactSupport' element={<ContactSupport />} />
        <Route path='KnowledgeBase' element={<KnowledgeBase />} />
      </Route>
      <Route path='Account'>
        <Route path='Profile' element={<Profile />} />
        <Route path='Invoices' element={<Invoices />} />
        <Route path='Calendar' element={<Calendar />} />
        <Route path='WorkOrders' element={<WorkOrders/>}/>
      </Route>
      <Route path='Accounts'>
        <Route path='Contracts' element={<Contracts />} />
        <Route path='Customers' element={<Customers />} />
      </Route>
      <Route path='*' element={<Error404 />} />
    </Routes>
  );
}

export default SiteRoutes;