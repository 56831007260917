import axios from 'axios';
import { ApiCallUrl } from '../Config/apiconfig';
import {
  cryptAPIKey, encryptUsingAES256Base64, decryptUsingAES256
} from './Security/Crypto';

export const ApiCall = async (StoredProcedure, data, database = null) => {
  try {
    data['StoredProcedure'] = StoredProcedure;
    if (database === null) {

      data['Database'] = 'WNSql';
    } else {
      data["Database"] = database;
    }
    const requestHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'X-ApiKey': cryptAPIKey(process.env.REACT_APP_SECRET_KEY)
    };

    const encryptedData = encryptUsingAES256Base64(JSON.stringify(data));
    const response = await axios.post(ApiCallUrl, encryptedData, { headers: requestHeaders, timeout: 30000 });

    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: `An error occurred: ${error.message}` };
  }
};
