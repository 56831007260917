import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { WebSocketStatsUrl } from '../../Config/apiconfig';

const WebSocketStats = () => {
    const [stats, setStats] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedApps, setExpandedApps] = useState({});

    useEffect(() => {
        fetchStats();

        const intervalId = setInterval(fetchStats, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const fetchStats = async () => {
        try {
            const response = await axios.get(WebSocketStatsUrl);
            setStats(response.data);
            setError(null);
        } catch (err) {
            setError('Failed to load WebSocket statistics');
            console.error('Error fetching WebSocket stats:', err);
        } finally {
            setLoading(false);
        }
    };

    const toggleAppDetails = (appName) => {
        setExpandedApps(prev => ({
            ...prev,
            [appName]: !prev[appName]
        }));
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center m-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger m-3" role="alert">
                <i className="fas fa-exclamation-circle me-2"></i>
                {error}
            </div>
        );
    }

    return (
        <div className="card m-3">
            <div className="card-header d-flex justify-content-between align-items-center">
                <h4 className="mb-0">WebSocket Statistics</h4>
                <button
                    className="btn btn-primary btn-sm"
                    onClick={fetchStats}
                >
                    Refresh
                </button>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-hover">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Application</th>
                                <th>Total Connections</th>
                                <th>Active Connections</th>
                                <th>Last Known Version</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(stats).map(([appName, appStats]) => (
                                <React.Fragment key={appName}>
                                    <tr>
                                        <td>
                                            <button
                                                type="button"
                                                className="btn btn-link p-0"
                                                onClick={() => toggleAppDetails(appName)}
                                                aria-expanded={expandedApps[appName]}
                                            >
                                                <i className={`fas fa-chevron-${expandedApps[appName] ? 'down' : 'right'}`}></i>
                                            </button>
                                        </td>
                                        <td>{appName}</td>
                                        <td>{appStats.totalConnections}</td>
                                        <td>{appStats.activeConnections}</td>
                                        <td>{appStats.lastKnownVersion}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5" className="p-0">
                                            <div className={`collapse ${expandedApps[appName] ? 'show' : ''}`}>
                                                <div className="p-3">
                                                    <table className="table table-sm table-bordered mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>State</th>
                                                                <th>Close Status</th>
                                                                <th>Close Description</th>
                                                                <th>Sub Protocol</th>
                                                                <th>Last Activity</th>
                                                                <th>IP Address</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {appStats.connections.map((conn, idx) => (
                                                                <tr key={idx}>
                                                                    <td>
                                                                        <span className={`badge ${
                                                                            conn.state === 'Open' ? 'bg-success' :
                                                                            conn.state === 'Closed' ? 'bg-danger' :
                                                                            'bg-warning'
                                                                        }`}>
                                                                            {conn.state}
                                                                        </span>
                                                                    </td>
                                                                    <td>{conn.closeStatus || '-'}</td>
                                                                    <td>{conn.closeStatusDescription || '-'}</td>
                                                                    <td>{conn.subProtocol || '-'}</td>
                                                                    <td>{new Date(conn.lastActivityTime).toLocaleString()}</td>
                                                                    <td>{conn.remoteIpAddress}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                            {Object.keys(stats).length === 0 && (
                                <tr>
                                    <td colSpan="5" className="text-center p-4">
                                        <i className="fas fa-plug fa-2x text-muted mb-3 d-block"></i>
                                        <p className="text-muted mb-0">No active WebSocket connections found</p>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default WebSocketStats;
