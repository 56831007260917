import React from 'react';
import { useSelector } from 'react-redux';
import { useVersionCheck } from '../../Hooks/useVersionCheck';
import packageJson from '../../../package.json';
import { ApiCall } from '../ApiCall';

export default function VersionNotification() {
  const { isNewer, isBehind } = useVersionCheck();
  const UserAccount = useSelector((state) => state.SiteWideSettings.UserAccount);

  const handleUpdateClick = async () => {
    var data = {
      'AppName': 'WN',
      'Version': packageJson.version
    }
    await ApiCall('usp_AppVersion', data, 'WNSql');
  };

  const shouldShowNotification = (isNewer && UserAccount?.UserType === 'Admin') || isBehind;

  return (
    shouldShowNotification ? (
        <div className="alert alert-light d-flex justify-content-center align-items-center py-1 shadow-sm rounded-3 mb-0">
          {isBehind ? (
            <>
              <span className="me-3 flex-shrink-0">
                A new update is ready ({packageJson.version})
              </span>
              <button
                className="btn btn-danger btn-sm flex-shrink-0"
                onClick={() => window.location.reload()}>
                Update Now
              </button>
            </>
          ) : (
            <>
              <span className="me-3 flex-shrink-0">
                The website version {packageJson.version} is newer than the database value!
              </span>
              <button
                className="btn btn-danger btn-sm flex-shrink-0"
                onClick={handleUpdateClick}>
                Update Database
              </button>
            </>
          )}
        </div>
    ) : null
  );
}