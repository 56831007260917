import { useState, useEffect } from 'react';
import versionWatcher from '../Services/VersionWatcher';

export function useVersionCheck() {
  const [versionStatus, setVersionStatus] = useState({
    isNewer: false,
    isBehind: false
  });

  useEffect(() => {
    // Skip version check if running on localhost
    if (window.location.hostname === 'localhost') {
      return;
    }

    // Initial version check
    const checkVersion = async () => {
      const status = await versionWatcher.checkVersion();
      
      setVersionStatus({
        isNewer: status === 'ahead',
        isBehind: status === 'behind'
      });
    };
    checkVersion();

    // Subscribe to future updates
    const unsubscribe = versionWatcher.subscribe(async () => {
      const status = await versionWatcher.checkVersion();
      setVersionStatus({
        isNewer: status === 'ahead',
        isBehind: status === 'behind'
      });
    });

    return () => unsubscribe();
  }, []);

  return versionStatus;
}